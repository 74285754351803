.card {
  /* max-width: 100%; */
  margin: 0;
  align-self: center;
}

.cardContainer {
  margin: 0;
  /* padding: 2rem; */

  display: grid;
  align-items: center;
  justify-content: center;
}
